<template>
	<div class="container-fluid">
		<div class="row justify-content-center align-items-center py-5 h-100 bg-credito" style="min-height: calc(100vh - 164px);">
			<div class="col-lg-8">
				<div class="card">
					<div class="card-header bg-primary text-white d-flex align-items-center">
						<div class="pt-1 px-1 mr-2 bg-light overflow-hidden w30px h30px rounded5px">
							<img v-lazy="imageUsuariosInversionista" class="img-fluid">
						</div>
						<h3 class="mb-0 font-weight-bold" v-if="validatePage">
							{{propuesta.aliasinv}} / inversionista 
						</h3>
						<span class="ml-auto">
							<router-link :to="{name:'perfil.index'}" v-if="propuesta.estado == 3 || propuesta.estado == 6">
								<img v-lazy="imageCloseWhite" width="15" height="15">
							</router-link>
						</span>
					</div>
					<div class="card-body px-5">
						<div class="row" v-if="validatePage">
							<div class="col-md-4 col-lg-3">
								<div class="mb-3">
									<img v-lazy="$filters.storage(propuesta.ruta, 120)" width="120" height="120" class="rounded-circle" v-if="propuesta.ruta" />
								</div>
								<div class="mb-3">
									<p class="mb-1">Monto ofertado (Q):</p>
									<strong>{{$filters.currency(propuesta.monto || 0)}}</strong>
								</div>
								<div class="mb-3">
									<p class="mb-1">Tasa de interés mensual:</p>
									<strong>{{propuesta.tasa}}% {{propuesta.tasaPeriodo}}</strong>
								</div>
								<div class="mb-3">
									<p class="mb-1">Tiempo de pago:</p>
									<strong>{{propuesta.tiempopago}} {{propuesta.tiempoPeriodo}}</strong>
								</div>
								<div class="mb-3">
									<p class="mb-1">Fecha creación:</p>
									<strong>{{propuesta.fecha}}</strong>
								</div>
							</div>
							<div class="col-md-8 col-lg-9">
								<div v-if="propuesta.estado!=3 && propuesta.estado!=6" class="text-right my-3">
									<label>Propuesta:&nbsp;</label>
									<strong>{{propuesta.nomEstado}}</strong>
								</div>
								<div v-if="propuesta.estado==3 || propuesta.estado==6">
									<div class="mb-3 bg-light rounded p-3" v-if="user.idusuario == propuesta.idusuarioinv">
										<h3 class="text-center font-weight-bold">Pasos de Finalización</h3>
										<div class="d-flex justify-content-between flex-column flex-lg-row">
											<div class="mb-3">
												<label>Fecha de firma de contrato:&nbsp;</label>
												<strong>{{$filters.date(propuesta.fechaContratoInv)}}</strong>
											</div>
											<div class="mb-3">
												<label>Propuesta:&nbsp;</label>
												<strong>{{propuesta.nomEstado}}</strong>
											</div>
										</div>
										<div>
											<label>Descripción:</label>
											<div class="card">
												<div class="card-body text-justify" style="min-height: 75px;">
													{{propuesta.pasosFinInv}}
												</div>
											</div>
										</div>
									</div>
									<div class="mb-3 bg-light rounded p-3" v-if="user.idusuario == propuesta.idusuarioprs">
										<h3 class="text-center font-weight-bold">Pasos de Finalización</h3>
										<div class="d-flex justify-content-between flex-column flex-lg-row">
											<div class="mb-3">
												<label>Fecha de firma de contrato:&nbsp;</label>
												<strong>{{$filters.date(propuesta.fechaContrato)}}</strong>
											</div>
											<div class="mb-3">
												<label>Propuesta:&nbsp;</label>
												<strong>{{propuesta.nomEstado}}</strong>
											</div>
										</div>
										<div>
											<label>Descripción:</label>
											<div class="card">
												<div class="card-body text-justify" style="min-height: 75px;">
													{{propuesta.pasosFin}}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="mb-3 bg-light rounded p-3">
									<p>Preguntas para esta propuesta:</p>
									<hr class="border-bottom border-primary" style="border-bottom-style: dashed !important; border-top-width: 0px;">
									<ul class="list-unstyled">
										<li :class="{'media mb-3':true, 'ml-5':pregunta.tipo == 'r'}" v-for="(pregunta, index) in preguntas" :key="index">
											<div class="pt-1 px-1 mr-3 rounded-circle bg-primary overflow-hidden w30px h30px" v-if="pregunta.alias == propuesta.aliasinv">
												<img v-lazy="imageUsuariosInversionista" class="img-fluid" :alt="pregunta.alias">
											</div>
											<div class="pt-1 px-1 mr-3 rounded-circle bg-primary overflow-hidden w30px h30px" v-if="pregunta.alias != propuesta.aliasinv">
												<img v-lazy="imageUsuarios" class="img-fluid" :alt="pregunta.alias">
											</div>
											<div class="media-body">
												<div class="bg-white rounded p-2">
													<strong>
													{{pregunta.alias}}
													</strong>
													<br>
													{{pregunta.pregunta}}
													<br>
												</div>
												<small class="text-muted mr-3" :title="pregunta.fecha">
													<TimeAgo :datetime="dateParse(pregunta.fecha)" />
												</small>
												<button type="button" v-on:click.prevent="showRespuesta(pregunta)" title="Responder" v-if="propuesta.estado == 1 && pregunta.idpregunta != (preguntas[index+1] || []).idpregunta" class="btn btn-link btn-sm">
													Responder
												</button>
											</div>
										</li>
									</ul>
								</div>
								<div>
									<div v-if="propuesta.estado == 1">
										<form ref="userFormPregunta" v-on:submit.prevent="send()" novalidate>
											<div class="mb-3">
												<div class="input-group align-items-center">
													<div class="input-group-prepend" v-if="preguntaselec">
														<span class="input-group-text py-0" id="basic-addon1" style="height: 42px;">
															@{{preguntaselec.alias}}
															<span v-on:click="resetSelect()" class="cursor-pointer">
																<span class="material-icons align-bottom" style="font-size: 15px">close</span>
															</span>
														</span>
													</div>
													<input id="pregunta" type="text" v-model="form.mensaje" name="pregunta" class="form-control bg-light" placeholder="Escribe aquí tu mensaje" min="10" maxlength="250" required ref="pregunta">
													<div class="input-group-append" style="height: 38px;">
														<button class="btn btn-outline-primary btn-sm border-0 bg-light" type="submit" id="button-addon-send" :disabled="loadingPregunta || !validateForm">
														<span class="material-icons">send</span>
														</button>
													</div>
												</div>
												<small class="text-danger" v-if="msjErrorRes">{{msjErrorRes}}</small>
												<small class="text-muted">**Tu pregunta pasara por un proceso de autorización</small>
											</div>
										</form>
									</div>
									<div v-if="form.mensaje">
										Está escribiendo un mensaje...
									</div>
									<div class="d-flex justify-content-end" v-else>
										<button type="button" v-if="propuesta.estado == 1 && user.idusuario == propuesta.idusuarioinv" v-on:click="retirarPropuesta()" :disabled="loadingPropuesta" class="btn btn-danger mr-3">
											Retirar propuesta
										</button>
										<router-link class="btn btn-success mr-3" :to="{name:'propuestas.edit', params:{id:propuesta.idpropuesta}}" v-if="propuesta.estado == 1 && user.idusuario == propuesta.idusuarioinv && propuesta.inversionista == 2" :disabled="loadingPropuesta">
											Editar propuesta
										</router-link>
										<button type="button" v-if="propuesta.estado == 1 && user.idusuario == propuesta.idusuarioprs" v-on:click="showConfirmAprob()" :disabled="loadingPropuesta" class="btn btn-success mr-3">
											Aprobar
										</button>
										<button type="button" v-if="propuesta.estado == 1 && user.idusuario == propuesta.idusuarioprs" v-on:click="showConfirm()" :disabled="loadingPropuesta" class="btn btn-danger">
											Rechazar
										</button>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="text-center">
							No tiene acceso a estos datos.
						</div>
					</div>
				</div>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const TimeAgo = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "TimeAgo" */"@/components/TimeAgo.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			TimeAgo
		},
		data(){
			return {
				loadingPropuesta: false,
				loadingPregunta: false,
				form: {
					mensaje: '',
				},
				msjErrorRes: '',
				preguntas: [],
				preguntaselec: null,
				validateForm: false,

				imageUsuariosInversionista: require('@/assets/image/usuarios_inversionista.webp'),
				imageCloseWhite: require('@/assets/image/close_white.webp')
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				if (!this.user.idusuario) {return}
				this.getProposalDetailInv()
				this.getProposalDetail()
				this.getQuestionProposal()
			},
			getProposalDetailInv(){
				this.loadingPropuesta = true
				this.$store.dispatch('getProposalDetailInv', {
					idpropuesta: this.id
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			getProposalDetail(){
				this.loadingPropuesta = true
				this.$store.dispatch('getProposalDetail', {
					idpropuesta: this.id
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			getQuestionProposal(){
				this.loadingPropuesta = true
				this.$store.dispatch('getQuestionProposal', {
					idpropuesta: this.id
				}).then(response => {
					this.preguntas = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			send(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				if (this.preguntaselec) {
					this.addResponse() //SendRespuesta
				}else{
					this.addQuestion() //sendPregunta
				}
			},
			addResponse(){
				this.loadingPregunta = true
				this.$store.dispatch('addResponse', {
					respuesta: this.form.mensaje,
					fechaCreacion: Math.round(new Date().getTime()/1000.0),
					idpregunta: this.preguntaselec?.idpregunta,
					idusuario: this.user.idusuario
				}).then(response => {
					window.toastr.info('Respuesta envíada exitosamente', 'Aviso')
					this.form.mensaje = ''
					this.resetSelect()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPregunta = false
				})
			},
			addQuestion(){
				this.loadingPregunta = true
				this.$store.dispatch('addQuestion', {
					pregunta: this.form.mensaje,
					fechaCreacion: Math.round(new Date().getTime()/1000.0),
					idpropuesta: this.id,
					idusuario: this.user.idusuario
				}).then(response => {
					window.toastr.info('Pregunta envíada exitosamente', 'Aviso')
					this.form.mensaje = ''
					this.resetSelect()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPregunta = false
				})
			},
			retirarPropuesta(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: 'Anulación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas anular esta propuesta?`,
					buttons: {
						anular: function () {
							mod.updateStatus(5)
				    	},
				    	cancelar: function () {

				    	},
				    }
				})
			},
			showConfirmAprob(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Aprobar!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas aprobar esta oferta?`,
					buttons: {
						aprobar: function () {
							mod.updateStatus(3);
						},
						cancelar: function () {
						},
					}
				})
			},
			showConfirm(){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Rechazar!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas rechazar esta oferta?`,
					buttons: {
						rechazar: function () {
							mod.updateStatus(4)
						},
				        cancelar: function () {
				        },
					}
				})
			},
			updateStatus(val){
				this.loadingPropuesta = true
				this.$store.dispatch('updateStatus', {
					estado: val,
					idpropuesta: this.id,
					notificar: val==5 ? 1 : 0
				}).then(response => {
					if (val==5) {
						window.toastr.info('La propuesta ha sido retirada', 'Aviso')
					}
					if (val==4) {
						window.toastr.info('La propuesta ha sido rechazada con éxito.', 'Aviso')
					}
					if (val==3) {
						window.toastr.info('La propuesta ha sido aprobada con éxito.', 'Aviso')
						this.approveProposal()
				        this.sendPush()
					}
					this.back()
					return response
				}).catch(error =>{
					if (error.response) {
						if (error.response.data.msg) {
							window.toastr.error(error.response.data.msg, 'Error')
						}else{
							window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
						}
					}
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			back(){
				if (this.propuesta.estado == 3 || this.propuesta.estado == 6) {
					this.$router.push({name: 'perfil.mispropuestas.autorizadas'})
				}
				if (this.propuesta.estado == 4 || this.propuesta.estado == 5) {
					this.$router.push({name: 'perfil.mispropuestas.denegadas'})
				}
				if (this.propuesta.estado == 1) {
					this.$router.push({name: 'perfil.mispropuestas.espera'})
				}
			},
			approveProposal(){
				this.$store.dispatch('approveProposal', {
					nomusuario: this.propuesta.nombreprs,
					apellido: this.propuesta.apellidoprs,
					mail: this.propuesta.idusuarioinv,
					nombrePrenda: this.propuesta.nombre,
					fotoPrenda:  this.propuesta.ruta,
					nomusuarioInv:this.propuesta.nombreinv,
					apellidoInv: this.propuesta.apellidoinv,
					montoOfertado: this.propuesta.monto,
					tasa: this.propuesta.tasa,
					tasaPeriodo: this.propuesta.tasaPeriodo,
					tiempoPeriodo: this.propuesta.tiempoPeriodo,
					tiempopago: this.propuesta.tiempopago,
					idpropuesta: this.propuesta.idpropuesta,
					fechaAprobado: Math.round(new Date().getTime()/1000.0)
				})
			},
			sendPush(){
				this.$store.dispatch('sendPush', {
					idusuario : this.propuesta.idusuarioinv, 
		        	title: '¡Aprobaron tu propuesta!', 
		        	message: 'Ha sido aprobada tu propuesta para ' + this.propuesta.nombre
				})
			},
			showRespuesta(val){
				this.preguntaselec = val
				this.$refs.pregunta.focus()
			},
			resetSelect(){
				this.preguntaselec = null
			},
			checkValidity(){
				if (!this.$refs.userFormPregunta){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userFormPregunta.checkValidity()
				}
			},
			dateParse(val){
				if(!val) return ''
				var dateTime = val.split(' ')
				var date = dateTime[0]
				var time = dateTime[1]

				var day = date.split('/')[0] || ''
				var month = date.split('/')[1] || ''
				var year = date.split('/')[2] || ''

				var hour = time.split(':')[0] || ''
				if(hour.length==1){
					hour = `0${hour}`
				}

				var minute = time.split(':')[1] || ''
				if(minute.length==1){
					minute = `0${minute}`
				}

				return `${year}/${month}/${day} ${hour}:${minute}`
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			propuesta(){
				return this.$store.getters.propuesta || {}
			},
			validatePage(){
				if (this.user.idusuario == this.propuesta.idusuarioinv || this.user.idusuario == this.propuesta.idusuarioprs) {
					return true
				}
				return false
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			},
			'form': {
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>