<template>
	<div class="modal fade" id="propuesta" data-backdrop="static" role="dialog" aria-labelledby="propuestaLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
			<form ref="formPropuesta" v-on:submit.prevent="sendPropuesta()" class="needs-validation was-validated w-100" novalidate>
			<div class="modal-content">
				<div class="modal-header bg-primary text-white align-items-center">
					<h3 class="modal-title text-left w-100">Propuesta</h3>
					<button type="button" class="btn btn-link" data-dismiss="modal" aria-label="Close">
						<img src="@/assets/image/close_white.webp" width="20" height="20">
					</button>
				</div>
				<div class="modal-body">
					<div class="bg-light rounded p-3 mb-3">
						<p class="text-center">¿Estás seguro que deseas modificar la oferta?</p>
						<table style="text-align: left; width: 100%;" class="table mb-0">
							<tr>
								<th>Monto:</th>
								<td align="right">{{$filters.currency(propuesta.precio || 0)}}</td>
							</tr>
							<tr>
								<th>Tasa de interés mensual:</th>
								<td align="right">{{propuesta.tasa}}% {{propuesta.tasaPeriodo}}</td>
							</tr>
							<tr>
								<th>Tiempo de pago(mensual):</th>
								<td align="right">{{propuesta.tiempopago}} {{propuesta.tiempoPeriodo}}</td>
							</tr>
							<tr v-if="propuesta.inversionista == 2">
								<th>Monto máximo permitido a ofertar:</th>
								<td align="right">{{$filters.currency(total || 0)}}</td>
							</tr>
						</table>
					</div>
					<Currency type="text" v-model="form.montoConfirmacion" class="form-control bg-light" placeholder="Confirmar monto" />
					<div class="text-danger">
						{{errorMontoConfirmacion}}
					</div>
			      </div>
		    	<div class="modal-footer text-right">
		    		<button type="submit" class="btn btn-success" :disabled="loadingPropuesta || !validateForm">
		    			<BtnLoading v-if="loadingPropuesta" />
						<span v-else>
							Ofertar
						</span>
		    		</button>
		    		<button class="btn btn-link" data-dismiss="modal">
		        		Cancelar
		    		</button>
		    	</div>
		  	</div>
		  	</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Currency = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			BtnLoading,
			Currency
		},
		data(){
		  return {
		  	loadingPropuesta: false,
		  	validateForm: false,
		  	form: {
		  		montoConfirmacion: 0,
		  	},
		  	errorMontoConfirmacion: ''
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
			this.form.montoConfirmacion = this.propuesta.monto || 0
		},
		methods: {
			sendPropuesta(){
				this.errorMontoConfirmacion = ''
				if (this.form.montoConfirmacion == parseFloat(this.propuesta.monto)) {
		        	this.errorMontoConfirmacion = 'El monto debe ser diferente a ' + this.$filters.currency(parseFloat(this.propuesta.monto || 0))
		        	return
		        }
		        if (!this.form.montoConfirmacion || this.form.montoConfirmacion <= 0 || this.form.montoConfirmacion > this.total) {
		        	this.errorMontoConfirmacion = 'El monto debe ser mayor a 0 y menor o igual a ' + this.$filters.currency(this.total || 0)
		        	return
		        }
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				var params = {
					monto: this.form.montoConfirmacion,
		            idpropuesta: this.propuesta.idpropuesta,
		            tasa: this.propuesta.tasa,
		            tiempopago: this.propuesta.tiempopago,
				}
				this.loadingPropuesta = true
				this.$store.dispatch('updatePropuesta', params).then(response => {
					window.toastr.info("Propuesta envíada exitosamente", "Éxito")
					var dd = this.propuesta
					dd.monto = this.form.montoConfirmacion
					this.$store.commit("misPropuestas",  dd)
					this.form.montoConfirmacion = 0
					this.mailsSendProposal(params)
					this.sendPush()
					window.$(this.$el).modal('hide')
					return response
				}).catch(error =>{
					window.toastr.error('Error al envíar propuesta', 'Error')
					return error
				}).then(() => {
					this.loadingPropuesta = false
				})
			},
			mailsSendProposal(params){
				this.$store.dispatch('mailsSendProposal', {
					nomusuario: this.propuesta.nombreprs,
	                apellido: this.propuesta.apellidoprs,
	                mail: this.propuesta.idusuarioprs,
	                nombrePrenda: this.propuesta.nombre,
	                idpropuesta: this.propuesta.idpropuesta,
	                fotoPrenda: this.propuesta.ruta,
	                nomusuarioInv: this.propuesta.nombreinv,
	                apellidoInv: this.propuesta.apellidoinv,
	                montoOfertado: params.monto,
	                tasa: params.tasa,
	                tasaPeriodo: this.propuesta.tasaPeriodo,
	                tiempoPeriodo: this.propuesta.tiempoPeriodo,
	                tiempopago: params.tiempopago,
	                fechaAprobado: Math.round(new Date().getTime()/1000.0)
				})
			},
			sendPush(){
				this.$store.dispatch('sendPush', {
					idusuario : this.propuesta.idusuarioprs, 
		        	title: '¡Tienes una nueva propuesta!', 
		        	message: 'Han realizado una propuesta para ' + this.propuesta.nombre
				})
			},
			checkValidity(){
				if (!this.$refs.formPropuesta){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.formPropuesta.checkValidity()
				}
			},
			toIndex(){
				this.$router.push({name: 'propuestas.show', params:{id: this.id}})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			propuesta(){
				return this.$store.getters.propuesta || {}
			},
			total(){
				return (parseFloat((this.propuesta.monto || 0))+(this.propuesta.montoPendiente || 0))
			},
			user(){
				return this.$store.getters.currentUser || {}
			}
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			'propuesta':{
				handler(val){
					this.form.montoConfirmacion = this.propuesta.monto || 0
					return val
				},
				deep: true
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>